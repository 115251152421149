import React from 'react'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import PrecisoinImageOne from "../../asset/precision2/1.jpg"
import PrecisoinImageTwo from "../../asset/precision2/2.png"
import PrecisoinImageThree from "../../asset/precision2/3.jpg"
import PrecisoinImageFour from "../../asset/precision2/4.jpg"
import PrecisoinImageFive from "../../asset/precision2/5.jpg"
import PrecisoinImageSix from "../../asset/precision2/6.jpg"
import PrecisoinImageSeven from "../../asset/precision2/7.png"
import PrecisoinImageEight from "../../asset/precision2/8.jpg"
import PrecisoinImageNine from "../../asset/precision2/9.png"
import PrecisoinImageTen from "../../asset/precision2/10.jpg"
import PrecisoinImageEleven from "../../asset/precision2/11.png"
import PrecisoinImageTwelve from "../../asset/precision2/12.jpg"
import PrecisoinImageThirteen from "../../asset/precision2/13.jpg"
import PrecisoinImageForteen from "../../asset/precision2/14.jpg"
import PrecisoinImageFifteen from "../../asset/precision2/15.jpg"
import PrecisoinImageSixteen from "../../asset/precision2/16.jpg"
import PrecisoinImageSeventeen from "../../asset/precision2/17.jpg"
import PrecisoinImageEigteen from "../../asset/precision2/18.jpg"
import PrecisoinImageNinteen from "../../asset/precision2/19.jpg"
import PrecisoinImageTwenty from "../../asset/precision2/20.jpg"
import PrecisoinImageTwentyOne from "../../asset/precision2/21.jpg"
import PrecisoinImageTwentyTwo from "../../asset/precision2/22.jpg"
import PrecisoinImageTwentyThree from "../../asset/precision2/23.jpg"
import PrecisoinImageTwentyFour from "../../asset/precision2/24.jpg"
import PrecisoinImageTwentyFive from "../../asset/precision2/25.jpg"
import PrecisoinImageTwentySix from "../../asset/precision2/26.jpg"
import PrecisoinImageTwentySeven from "../../asset/precision2/27.jpg"
import PrecisoinImageTwentyEight from "../../asset/precision2/28.jpg"
const PrecisionTwo = () => {
  return (
    <div className='lg:pt-6 lg:pb-6 py-4 '>
        <p className='text-gray-600 text-[.8rem] text-center xl:text-[1rem] mb-3'>We specialize in manufacturing high-precision components with diameters up to 1000 mm, offering exceptional geometric tolerances such as concentricity, straightness, run-out, total run-out, and circularity. Our capabilities extend to working with exotic materials, ensuring durable and reliable solutions for diverse industrial applications.
        With an in-house heat treatment furnace for processing raw materials up to 500 mm, we guarantee optimal material properties and performance. Whether you need custom sizes, advanced materials, or tight tolerances, our team delivers precision-engineered components that meet the highest standards.</p>
        <div>
        <ResponsiveMasonry
      guttter="3rem"
                columnsCountBreakPoints={{350: 1, 750: 2, 950: 3,1300:4,1600:4,1800:4}}
            >
                <Masonry>
                <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageOne} />
                    <h6 className='text-center mt-2 ' >SLEEVE</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTwo} />
                    <h6 className='text-center mt-2 ' >FRONT PIECE BODY</h6>
                   </div>
                  
                  
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageThree} />
                    <h6 className='text-center mt-2 ' >SHOCK ABSORBER</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageThirteen} />
                    <h6 className='text-center mt-2 ' >WEDGES</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageFour} />
                    <h6 className='text-center mt-2 ' >4-START THRD INSERT</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageFive} />
                    <h6 className='text-center mt-2 ' >ZIRCALOY</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTwenty} />
                    <h6 className='text-center mt-2 ' >C-RAM HEAD</h6>
                   </div>
                   <div className='my-3 mx-3' >
                    <img className='rounded-lg w-full ' src={PrecisoinImageSix} />
                    <h6 className='text-center mt-2 ' >SCREW BEARING</h6>
                   </div>
                 
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTwentyOne} />
                    <h6 className='text-center mt-2 ' >C_RAM HEAD</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageEigteen} />
                    <h6 className='text-center mt-2 ' > B-RAM HEAD</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageForteen} />
                    <h6 className='text-center mt-2 ' >STEAM EJECTOR</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageSeven} />
                    <h6 className='text-center mt-2 ' >DPCV SLEEVE</h6>
                   </div>
                  
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageSeventeen} />
                    <h6 className='text-center mt-2 ' > BUMPER</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTwelve} />
                    <h6 className='text-center mt-2 ' >J-BAYONET</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageNinteen} />
                    <h6 className='text-center mt-2 ' >CASING</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageEight} />
                    <h6 className='text-center mt-2 ' >JAWS</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageNine} />
                    <h6 className='text-center mt-2 ' >NOZZLE</h6>
                   </div>
                  
                  
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageSixteen} />
                    <h6 className='text-center mt-2 ' >SPIDER </h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTen} />
                    <h6 className='text-center mt-2 ' >FORGED WEDGE</h6>
                   </div>
               
                 
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageEleven} />
                    <h6 className='text-center mt-2 ' >BEARING INSERT</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageFifteen} />
                    <h6 className='text-center mt-2 ' >RAM DRIVE END BODY</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTwentyTwo} />
                    <h6 className='text-center mt-2 ' >K-MONEL PAWL</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTwentyThree} />
                    <h6 className='text-center mt-2 ' >LATCH SLEEVE</h6>
                   </div>
                   
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTwentySeven} />
                    <h6 className='text-center mt-2 ' >ADVANCE PRESSURE COMPENSATOR SPOOL</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTwentyEight} />
                    <h6 className='text-center mt-2 ' >HIGH FLOW CONTROL VALVE COMPONENTS</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTwentyFive} />
                    <h6 className='text-center mt-2 ' >DIFFERENTIAL PRESSURE CONTROL VALVE</h6>
                   </div>
                   <div className='my-3 mx-3'>
                    <img className='rounded-lg w-full ' src={PrecisoinImageTwentyFour} />
                    <h6 className='text-center mt-2 ' >GRIPPER SPRING</h6>
                   </div>
                  
                  
                 
                  
                   
                  
                
                  
                   
                 
                   
                 
                </Masonry>
            </ResponsiveMasonry>
        </div>
    </div>
  )
}

export default PrecisionTwo