import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar'
import Footer from '../components/Layout/Footer'
import BottomEndPlug from "../asset/precisionone/bottomend.png"
import CAPSULE from "../asset/precisionone/capsule.png"
import LINK from "../asset/precisionone/link.png"
import TopEnd from "../asset/precisionone/topend.png"
import Pinion from "../asset/precisionone/pinion.png"
import Capsuletwo from "../asset/precisionone/capsule2.png"
import Poppet from "../asset/precisionone/poppet.png"
import PoppetTwo from "../asset/precisionone/poppettwo.png"
import Valve from "../asset/precisionone/valve.png"
import LVDT from "../asset/precisionone/lvdt.png"
import Feed from "../asset/precisionone/feed.png"
import Valvetwo from "../asset/precisionone/valvetwo.png"
import ChromiumOxide from "../asset/coating/chromium.png"
import HardchromeOxide from "../asset/coating/hardchrome.png"
import Nitriding from "../asset/coating/nitriding.png"
import Cadmium from "../asset/coating/cadmium.png"
import Diamond from "../asset/coating/diamond.png"
import Zinc from "../asset/coating/zinccoating.png"
import PrecisionTwo from '../components/Precision/PrecisionTwo'
import PrecisionThree from '../components/Precision/PrecisionThree'
const PrecisionComponentOne = () => {
  return (
    <div>
    <NavbarTwo />
    <div className='2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]'>
    <div className='bg-gray-200  lg:pt-24 lg:pb-6 py-4 px-4 lg:px-12'>
            <div className='flex items-center justify-center flex-col' >
            <h3 className='lg:text-[2.2rem] text-[1.2rem] mb-3 lg:mb-4 bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]' >COATING AND PLATING COMPONENTS</h3>
            <p className='text-gray-600 text-[.8rem] text-center xl:text-[1rem]' >At HARDCOATS, we specialize in high-quality precision components with advanced plating and coating solutions, including chromium oxide coating, hard chrome plating, Nitriding, cadmium plating, DLC coating, and zinc-phosphating. While we partner with trusted, approved vendors for the coating processes, we handle the critical pre- and post-machining stages in-house. This ensures that every component meets the highest standards of precision and performance. Our in-house capabilities, combined with our strong network of coating partners, allow us to deliver reliable, cost-effective solutions tailored to your specific requirements, with a focus on quality and consistency.            </p>
            </div>
            <div className='py-6 lg:py-12' >
                <div className='grid grid-cols-1 my-6 xl:gap-12 gap-6 lg:grid-cols-3' >
                   <div>
                    <img className='rounded-lg' src={ChromiumOxide} />
                    <h6 className='text-center mt-2 ' >CHROMIUM OXIDE COATED COMPOENENTS</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={HardchromeOxide} />
                    <h6 className='text-center mt-2 ' >HARD CHROME PLATING COMPONENTS</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Nitriding} />
                    <h6 className='text-center mt-2 ' >NITRIDING COMPONENTS</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Diamond} />
                    <h6 className='text-center mt-2 ' >CADMIUM PLATING COMPONENTS</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Cadmium} />
                    <h6 className='text-center mt-2 ' >DIAMOND LIKE COATING (DLC) COMPONENTS</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Zinc} />
                    <h6 className='text-center mt-2 ' >ZINC-PHOSPHATING COATING</h6>
                   </div>
                </div>
            </div>
           
        </div>
        <div className='bg-gray-200  lg:pb-12 pb-4 px-4 lg:px-12'>
            <div className='flex items-center justify-center flex-col' >
            <h3 className='lg:text-[2.2rem] text-[1.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]' >PRECISION COMPONENTS - I</h3>
            <p className='text-gray-600 text-[.8rem] text-center xl:text-[1rem]'>We offer a wide range of precision-engineered components, including Poppets, Plugs, Pins, Valves Piston etc. Manufactured from high-performance materials such as Stainless Steel, Titanium, Incoloy, Inconel, 17-4 pH with required heat treated condition, our components are designed with tight tolerances of up to 2 microns and Ra finishes up to 0.02Ra. These parts are ideal for use in power and energy sectors providing durable and reliable solutions for various applications.</p>
            </div>
            <div className='py-6 lg:py-12' >
                <div className='grid grid-cols-1 my-6 xl:gap-12 gap-6 lg:grid-cols-4' >
                   <div>
                    <img className='rounded-lg' src={BottomEndPlug} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >BOTTOM END PLUG</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={CAPSULE} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >CAPSULE</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={LINK} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >LINK</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={TopEnd} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >TOP END PLUG</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Pinion} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >PINION EXTENSION</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Capsuletwo} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >CAPSULE</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Poppet} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >POPPET </h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Valve} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >VALVE PISTON</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={LVDT} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >LVDT SLEEVE</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={PoppetTwo} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >POPPET -II</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Feed} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >FEED ROD</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Valvetwo} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >VALVE PISTON</h6>
                   </div>
                </div>
            </div>
           <PrecisionTwo />
           <PrecisionThree />
        </div>
    <Footer />
    </div>
</div>
  )
}

export default PrecisionComponentOne