import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar'
import Footer from '../components/Layout/Footer'
import BottomEndPlug from "../asset/precisiontwo/1.png"
import CAPSULE from "../asset/precisiontwo/2.png"
import LINK from "../asset/precisiontwo/3.png"
import TopEnd from "../asset/precisiontwo/4.png"
import Pinion from "../asset/precisiontwo/5.png"
import Capsuletwo from "../asset/precisiontwo/6.png"
import Poppet from "../asset/precisiontwo/7.png"
import PoppetTwo from "../asset/precisiontwo/8.png"
import Valve from "../asset/precisiontwo/9.png"
import LVDT from "../asset/precisiontwo/10.png"
import Feed from "../asset/precisiontwo/11.png"
import Valvetwo from "../asset/precisiontwo/12.png"

const PrecisionComponentTwo = () => {
  return (
    <div>
    <NavbarTwo />
    <div className='2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]'>
        <div className='bg-gray-200  lg:py-24 py-4 px-4 lg:px-12'>
            <div className='flex items-center justify-center flex-col' >
            <h3 className='lg:text-[2.2rem] text-[1.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]' >PRECISION COMPONENTS - II</h3>
            
            </div>
            <div className='py-6 lg:py-12' >
                <div className='grid grid-cols-1 my-6 xl:gap-12 gap-6 lg:grid-cols-4' >
                   <div>
                    <img className='rounded-lg' src={BottomEndPlug} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >CONNECTION FLANGE - I</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={CAPSULE} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >CONNECTION FLANGE - II</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={LINK} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >CONNECTION FLANGE - III</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={TopEnd} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >SPLIT COLLAR</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Pinion} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >BAGUE ARRET D40 </h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Capsuletwo} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >SUPPORT CLAMP - I</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Poppet} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >BAGUE ARRET D50</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Valve} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >ADAPTOR</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={LVDT} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >TIP 005</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={PoppetTwo} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >SPACING CONE</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Feed} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >FLAT BAR</h6>
                   </div>
                   <div>
                    <img className='rounded-lg' src={Valvetwo} />
                    <h6 className='text-center mt-2 xl:text-[.8rem] text-[.7rem] ' >FRAME PLATE</h6>
                   </div>
                </div>
            </div>
           
        </div>
    <Footer />
    </div>
</div>
  )
}

export default PrecisionComponentTwo