import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar'
import Footer from '../components/Layout/Footer'

const CoatingandPlating = () => {
  return (
    <div>
    <NavbarTwo />
  
</div>
  )
}

export default CoatingandPlating