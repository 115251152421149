import React from 'react'
import { NavbarTwo } from '../components/Layout/Navbar'
import Footer from '../components/Layout/Footer'
import PistonsealOne from "../asset/pistonseal/1.png"
import PistonsealTwo from "../asset/pistonseal/2.png"
import PistonsealThree from "../asset/pistonseal/3.png"
import PistonsealFour from "../asset/pistonseal/4.png"
import PistonsealFive from "../asset/pistonseal/5.png"
import MetallicsealOne from "../asset/metallicseal/1.png"
import MetallicsealTwo from "../asset/metallicseal/2.png"
import NozzleOne from "../asset/metallicseal/nozzle1.png"
import NozzleTwo from "../asset/metallicseal/nozzle2.png"
import WaterOne from "../asset/metallicseal/Picture3 (1).jpg"
const IndegeniselyDevelopedSeals = () => {
  return (
    <div >
        <NavbarTwo />
        <div className='2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]'>
            
            <div className='bg-gray-200  lg:pt-24 lg:pb-6 pt-12 pb-4 px-4 lg:px-12'>
                <div className='flex items-center justify-center flex-col' >
                <h3 className='lg:text-[2.2rem] text-[1.6rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]' >INDEGENISELY DEVELOPED SEALS</h3>
                </div>
                <div className='py-6 lg:py-12' >
                    <h5 className='text-black text-[1.2rem] font-[700] lg:text-[1.5rem]' >PISTON ROD SEALS</h5>
                    <p className='text-gray-600 text-[.8rem] xl:text-[1rem]' >At HARDCOATS, we manufacture two types of high-performance CGB dynamic seals: Positive Segment Seals and Zero Leakage Seals. The Positive Segment Seal is designed to allow minimal leakage, acting as a lubricant between the stationary and moving shafts to reduce friction. This seal provides effective performance where some leakage is tolerable, while still improving overall efficiency. In contrast, the Zero Leakage Seal is engineered for applications where absolutely no leakage is allowed, ensuring airtight sealing under extreme conditions. These seals are critical in the Fueling Machine Head of the nuclear sector, where they must withstand high pressures of up to 145 kg/cm² and endure a shaft oscillation of 3 meters in 30 seconds, all while maintaining superior sealing performance.</p>
                    <div className='grid grid-cols-2 items-center justify-center my-6 xl:gap-8 gap-3 lg:grid-cols-5' >
                        <img className='rounded-lg' src={PistonsealOne} />
                        <img className='rounded-lg' src={PistonsealTwo} />
                        <img className='rounded-lg' src={PistonsealThree} />
                        <img className='rounded-lg' src={PistonsealFour} />
                        <img className='rounded-lg' src={PistonsealFive} />
                    </div>
                </div>
                <div className='py-6 lg:py-12' >
                    <h5 className='text-black text-[1.2rem] font-[700] lg:text-[1.5rem]' >METALLIC FACE SEAL</h5>
                    <p className='text-gray-600 text-[.8rem] xl:text-[1rem]' >The Metallic Face Seal and K Seal is manufactured from age-hardened Inconel X-750 material, designed for leak-tight water sealing at 200 kg/cm² pressure and 500°C temperature. Precisely crafted with dimensions controlled within 0.003mm, it ensures strength and flexibility. The seal’s design, developed by HARDCOATS, incorporates fatigue analysis, contact stress considerations, and a self-energizing philosophy. Our innovative manufacturing technology achieves a surface finish of 0.1 micron and a groove thickness of 0.25 mm, meeting the most stringent performance requirements.</p>
                    <div className='grid grid-cols-1 my-6 xl:gap-8 gap-6 lg:grid-cols-2' >
                        <img className='rounded-lg' src={MetallicsealOne} />
                        <img className='rounded-lg' src={MetallicsealTwo} />
                        
                    </div>
                </div>
                <div className='py-6 grid grid-cols-1 lg:grid-cols-2 items-center lg:gap-12 gap-4 lg:py-12' >
                    <div>
                    <h5 className='text-black text-[1.2rem] font-[700] lg:text-[1.5rem]' >NOZZLE DAM ASSEMBLY</h5>
                    <p className='text-gray-600 text-[.8rem] xl:text-[1rem]' >The Nozzle Dam Assembly is used during normal shutdown and maintenance periods in a nuclear power plant to significantly shorten the time required for examining and maintaining the tubes of a steam generator, which can be performed simultaneously with refueling. The Nozzle Dam Assembly in a steam generator acts as a closure to block the flow of coolant from the inlet and outlet nozzles. These assemblies are made from aluminum and titanium materials. This Assembly is designed and developed by Hardcoats. </p>
                    </div>
                    <div className='grid grid-cols-1 my-6 xl:gap-8 gap-6 lg:grid-cols-2' >
                        <img className='rounded-lg' src={NozzleOne} />
                        <img className='rounded-lg' src={NozzleTwo} />
                        
                    </div>
                </div>
                <div className='py-6 grid grid-cols-1 lg:grid-cols-2 items-center lg:gap-12 gap-4 lg:py-12' >
                   
                    <div className='order-2 lg:order-1' >
                        <img className='rounded-lg' src={WaterOne} />
                      
                        
                    </div>
                    <div className='order-1 lg:order-2' >
                    <h5 className='text-black text-[1.2rem] font-[700] lg:text-[1.5rem]' >WATER LUBRICATED BEARING</h5>
                    <p className='text-gray-600 text-[.8rem] xl:text-[1rem]' >At Hardcoats, we specialize in the design, development, and manufacturing of high-performance water-lubricated bearings, offering a reliable solution for a range of industrial applications that require optimal performance in water-based environments. Our bearings are engineered to handle both radial and axial loads with precision, making them the ideal choice for various drive mechanisms operating exclusively under water lubrication. We offer a comprehensive range of water-lubricated bearings in sizes ranging from 16 mm to 280 mm, ensuring that each bearing is tailored to meet the specific needs of your application. Our offerings include Deep Groove, Thrust Bearing, Angular Contact and Taper Roller type Bearing.</p>
                    </div>
                </div>
            </div>

        </div>
       
    <Footer />
    </div>
  )
}

export default IndegeniselyDevelopedSeals