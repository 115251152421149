
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Aboutus from './pages/AboutUs.js';
import MissionVision from './pages/MissionVision.js';
import TestingPage from './pages/TestingPage.js';
import ManufacturingPage from './pages/ManufacturingPage.js';
import Qualitypage from './pages/Qualitypage.js';
import ResearchPage from './pages/ResearchPage.js';
import CertificationPage from './pages/CertificationPage.js';
import ContactPage from './pages/ContactPage.js';
import CareerPage from './pages/CareerPage.js'
import IndegeniselyDevelopedSeals from './pages/IndegeniselyDevelopedSeals.js';
import CoatingandPlating from './pages/CoatingandPlating.js';
import PrecisionComponentOne from './pages/PrecisionComponentOne.js';
import PrecisionComponentTwo from './pages/PrecisionComponentTwo.js';

function App() {
  return (
    <div className="App">
     <Routes>
     <Route path="/" element={<Home />} />
     <Route path="/aboutus" element={<Aboutus/>} />
     <Route path="/mission-vision" element={<MissionVision/>} />
     <Route path="/testing" element={<TestingPage/>} />
     <Route path="/manufacturing" element={<ManufacturingPage/>} />
     <Route path="/quality" element={<Qualitypage/>} /> 
     <Route path="/research" element={<ResearchPage/>} /> 
     <Route path="/certification" element={<CertificationPage/>} /> 
     <Route path="/indegenisely-developed-seals" element={<IndegeniselyDevelopedSeals />} /> 
     <Route path="/coating-and-plating" element={<CoatingandPlating />} /> 
     <Route path="/precision-components-one" element={<PrecisionComponentOne />} /> 
     <Route path="/precision-components-two" element={<PrecisionComponentTwo />} /> 
     <Route path="/career" element={<CareerPage/>} /> 
     <Route path="/contact" element={<ContactPage/>} /> 
     </Routes>
    </div>
  );
}

export default App;
