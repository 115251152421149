import React from 'react'
import PrecisoinImageOne from "../../asset/precisionthree/1.png"
import PrecisoinImageTwo from "../../asset/precisionthree/2.png"
import PrecisoinImageThree from "../../asset/precisionthree/3.png"
import PrecisoinImageFour from "../../asset/precisionthree/4.png"
const PrecisionThree = () => {
  return (
    <div className='py-4 lg:py-12 gap-8 lg:gap-12 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2' >
        <div className=''>
                    <img className=' w-full ' src={PrecisoinImageOne} />
                    <h6 className='text-center font-[GilroyBold] mt-2 text-[1.2rem] lg:text-[1.5rem] ' >INCOENEL X-750 HELICAL SPRING</h6>
                    <p className='text-[.7rem] font-[GilroyRegular] lg:text-[.8rem] text-center'>Inconel X-750 Compression Springs are widely used in extreme environment condition where tremendous heat and corrosion resistance is paramount to the integrity of the end product. we manufacture it from wire size 0.4 mm to 10.0 mm conforming to AMS 5699. This Spring offers high strength at temperatures up to 1,300°F (704°C)</p>
                    <p></p>
                   </div>
                   <div className=''>
                    <img className=' w-full ' src={PrecisoinImageTwo} />
                    <h6 className='text-center font-[GilroyBold] mt-2 text-[1.2rem] lg:text-[1.5rem] ' >INCONEL  X-750 ROLL PIN</h6>
                    <p className='text-[.7rem] font-[GilroyRegular] lg:text-[.8rem] text-center'>Inconel – X 750 Roll Pins are manufactured from size 3.0 mm to 20.0 mm conforming to AMS 5542. This Roll Pins are manufactured in a precise and controlled fashion. offers high strength at temperatures up to 1,300°F (704°C). This Roll Pins are mainly used in extreme environment condition </p>
                   </div>
                   <div className=''>
                    <img className=' w-full ' src={PrecisoinImageThree} />
                    <h6 className='text-center font-[GilroyBold] mt-2 text-[1.2rem] lg:text-[1.5rem] ' >BEVEL GEAR AND PINION</h6>
                    <p className='text-[.7rem] font-[GilroyRegular]  lg:text-[.8rem] text-center'>We manufacture precision Aluminium bronze bevel gears, steel pinions, latch gears, and splines. Our Aluminium bronze bevel gears offer excellent strength and corrosion resistance, ideal for marine and aerospace applications.
                 <br />   Our steel pinions are crafted for smooth meshing and reliable performance, while our latch gears ensure dependable engagement and release. We also produce custom splines with tight tolerances for a precise fit and durability.</p>
                   </div>
                   <div className=''>
                    <img className=' w-full ' src={PrecisoinImageFour} />
                    <h6 className='text-center font-[GilroyBold] mt-2 text-[1.2rem] lg:text-[1.5rem]' >PRECISION PISTON TUBES</h6>
                    <p className='text-[.7rem] font-[GilroyRegular] lg:text-[.8rem] text-center'>Our precision machining services extend to tubes up to 6 meters in length. We perform operations such as slotting, grooving, and threading while maintaining strict geometric tolerances, including concentricity, run-out, and total run-out. Additionally, we offer hydro-testing to ensure the reliability and integrity of the tubes for high-performance applications.
                 <br />   From complex machining to stringent quality requirements, our precision machining ensures accuracy and durability for every component.</p>
                   </div>
    </div>
  )
}

export default PrecisionThree